<!--
 * @Author: Do not edit
 * @Date: 2021-03-16 09:31:58
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-18 14:18:01
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\views\About.vue
-->
<template>
  <div class="about">
    <div class="banner">
      <div class="txt-inner  wow bounceInRight">
        <h3>我们一直坚持的目标</h3>
        <p><span>简单</span> <span>赋能</span> <span>创新</span> <span>极致</span></p>
      </div>
    </div>
    <div class="cent">
      <div class="inner-title wow fadeInUp">关于我们</div>
      <div class="txt-cent wow fadeInUp">
        <p class="txt">
          湖南英富莱网络技术有限公司始建于2016年，经过5年时间和近亿元的投入，精心打造出en+数字化赋能平台（简称en+数字化）。该平台是目前国内唯一的一家具备零代码搭建能力的业财税一体化的数字化赋能平台，也是国内领先的赋能企业规范上市的数字化服务商。en+数字化通过“1+N”的模式，即通过一个数字化底座实现N种数字化能力和方案，赋能企业落地经营管理的全场景数字化（包括人，财，业，物的多维数字化场景融合），并结合产业，数字化技术与金融的三大要素推动产业数字化转型。
        </p>
        <p class="txt">
          en+数字化已经参与和实现了各类行业与产业数字化的建设，包括数字化物流，数字化医疗，数字化汽贸城，数字化工地，数字化园区，数字化工程管理，数字化律所，数字化农业，智能制造，数字化环保节能等行业或产业的硬软结合的数字解决方案。
        </p>
        <ul>
          <li>
            <i class="icons icon1"></i
            ><span>湖南省长沙市岳麓区金星中路247号佳兴国际汇写字楼20层</span>
          </li>
          <li><i class="icons icon2"></i><span>400-617-9001</span></li>
          <li><i class="icons icon3"></i><span>kf@enfry.com</span></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";

export default {
  name: "About",
  mounted() {
    this.$nextTick(() => {
      const wow = new WOW({
        live: false
      });
      wow.init();
    });
  }
};
</script>
<style lang="scss" scoped>
.banner {
  background: url("~@/assets/images/banner-about.jpg") no-repeat bottom center;
  height: 635px;
  position: relative;
  .txt-inner {
    text-align: center;
    padding-top: 240px;
    h3 {
      font-size: 48px;
      margin: 0;
      font-weight: normal;
    }
    p {
      font-size:58px;
      text-align: center;
      margin:50px 0;
      span{
        margin: 0 20px;
        position: relative;
        &:after{
          content:"";
          height: 6px;
          width: 54px;
          background: #42a69c;
          position: absolute;
          bottom: -20px;
          left: 30px;
        }
        &:first-child{
          &::after{
          background: #187ea8;
          }
        }
      }
    }
  }
}

.bannertxt {
  position: absolute;
  top: 250px;
  left: 55%;
  margin-left: -185px;
}
.inner-title {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  line-height: 34px;
  padding-top: 66px;
}
.txt-cent {
  width: 770px;
  margin: 0 auto;
  color: #333;
  font-size: 14px;
  text-align: left;
  margin-top: 50px;
  line-height:30px;
  p{
    text-indent:28px;
  }
}
.cent {
  height: 700px;
}
ul {
  padding: 0;
  margin-top: 56px;
}

li {
  list-style: none;
  margin-bottom:10px;
  position: relative;
  padding-left: 28px;
  span {
    display: inline-block;
    vertical-align: middle;
  }
}
.icons {
  background: url("~@/assets/images/iconbg.png") no-repeat center;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  position: absolute;
  top: 5px;
  left: 0;
}
.icon1 {
  background-position: 0 0;
}
.icon2 {
  background-position: -25px 0;
}
.icon3 {
  background-position: -47px 0;
}
@media (max-width: 750px) {
  .banner {
    height: 158px;
    overflow: hidden;
    background-size: 150%;
    background-position: top center;
    background-color:#fff;
    .txt-inner {
      padding-top:40px;
      h3 {
        font-size: 24px;
        margin: 0;
      }
      p {
        font-size: 20px;
        margin-top: 18px;
      }
      p span:after{
        content: "";
        height: 6px;
        width: 42px;
        background: #42a69c;
        position: absolute;
        bottom: -13px;
        left: 0;
      }
    }
  }
  ul{
    margin-top:20px
  }
  .inner-title {
    padding-top: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  .cent{
    height:680px;
  }
  .txt-cent {
    width: 90%;
    margin: 0 auto;
  }
}
@media (max-width: 320px) {
  .banner{
    background-size: 197%;
    .txt-inner p span{
      margin: 0 10px;
    }
  }
}
</style>
